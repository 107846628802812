import {
  FETCH_DATA_RESUME_CASH,
  SET_DATA_RESUME_CASH,
  SET_LOGINS,
  ADD_LOGINS,

  // Tickets Abiertos
  FETCH_OPENED_TICKETS,
  SET_OPENED_TICKETS,
  ADD_OPENED_TICKETS,
  UPDATE_OPENED_TICKETS,
  REMOVE_OPENED_TICKET,
  // Tickets Cerrados
  FETCH_CLOSED_TICKETS,
  SET_CLOSED_TICKETS,
  ADD_CLOSED_TICKET,
  UPDATE_CLOSED_TICKETS,
  REMOVE_CLOSED_TICKET,
  UPDATE_RESUME_CASH_STATE,
  SET_FOREGROUND,
  SET_TICKETS_STATS,
  FETCH_TICKETS_STATS,
  SET_CC,
  SET_VERSION_DB,
  SET_CC_VEHICLES,
  SET_APPCONFIG,
} from "./actionTypes";

const initialState = {
  customer_id: "150461130934066",
  dataResumeCash: [],
  openedTickets: [],
  closedTickets: [],
  ticketsStats: [],
  foreground: false,
  logins: [],
  cc: [],
  cc_vehicles: [],
  versionDB: "0.0.0",
  appConfig: {}
};

const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DATA_RESUME_CASH:
            return {
                ...state,
                dataResumeCash: true
            }
        case SET_DATA_RESUME_CASH:
            return {
                ...state,
                dataResumeCash: action.payload
            }

        case UPDATE_RESUME_CASH_STATE:
            return {
                ...state,
                dataResumeCash: action.payload,
            };

        
        
        // TICKETS ABIERTOS
        case FETCH_OPENED_TICKETS:
            return {
                ...state,
                fetchOpenedTickets: action.payload
            }

        case SET_OPENED_TICKETS:
            return {
                ...state,
                openedTickets: action.payload
            }

        case ADD_OPENED_TICKETS:
            return {
                ...state,
                openedTickets: [action.payload, ...state.openedTickets]
            }

        case UPDATE_OPENED_TICKETS:

            // find by id 
            const ticketIndex = state.openedTickets.findIndex(ticket => ticket.id === action.payload.id)
            const newTickets = [...state.openedTickets]
            newTickets[ticketIndex] = action.payload
            return {
                ...state,
                openedTickets: newTickets
            }

        case REMOVE_OPENED_TICKET:
            return {
                ...state,
                openedTickets: [...state.openedTickets.filter(ticket => ticket.id !== action.payload.id)]
            }



        // TICKETS CERRADOS

        case FETCH_CLOSED_TICKETS:
            return {
                ...state,
                fetchingClosedTickets: action.payload
            }

        case SET_CLOSED_TICKETS:
            return {
                ...state,
                closedTickets: action.payload
            }

        case ADD_CLOSED_TICKET:
            return {
                ...state,
                openedTickets: [...state.openedTickets.filter(ticket => ticket.id !== action.payload.id)],
                closedTickets: [action.payload, ...state.closedTickets]
            }

        case UPDATE_CLOSED_TICKETS:
            const ticketIndexClosed = state.closedTickets.findIndex(ticket => ticket.id === action.payload.id)
            const newClosedTickets = [...state.closedTickets]
            newClosedTickets[ticketIndexClosed] = action.payload
            return {
                ...state,
                closedTickets: newClosedTickets
            }

        case REMOVE_CLOSED_TICKET:
            return {
                ...state,
                closedTickets: [...state.closedTickets.filter(ticket => ticket.id !== action.payload.id)]
            }

        

        case FETCH_TICKETS_STATS:
            return {
                ...state,
                ticketsStats: true
            }

        case SET_TICKETS_STATS:
            return {
                ...state,
                ticketsStats: action.payload
            }

        case SET_LOGINS:
            return {
                ...state,
                logins: action.payload
            }

        case ADD_LOGINS:
            return {
                ...state,
                logins: [action.payload, ...state.logins]
            }

        case SET_CC:
            return {
                ...state,
                cc: action.payload
            }
        
        case SET_CC_VEHICLES:
            return {
                ...state,
                cc_vehicles: action.payload
            }

        case SET_FOREGROUND:
            return {
                ...state,
                foreground: action.payload
            }
        
        case SET_VERSION_DB:
            return {
                ...state,
                versionDB: action.payload
            }
        
        case SET_APPCONFIG:
                    return {
                        ...state,
                        appConfig: action.payload
            }
        
        default:
            return state
    }
}

export default dataReducer
